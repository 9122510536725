const document = `
<h1>Privacy Policy</h1>
<p>Updated: December 1, 2024</p>
<p><strong>Welcome to Smart’n - The GPT for nurses.</strong></p>
<p>This Privacy Policy explains how Smart’n ("we," "us," "our") collects, uses, discloses, and protects your personal information when you interact with our platform, which includes our website, mobile app, digital experiences, and other related products or services (collectively, the “Platform”).</p>
<p>By accessing or using the Platform, you agree to the terms of this Privacy Policy. If you do not agree, please do not use the Platform.</p>
<p>We protect your privacy and adhere to applicable data protection laws. Below, we outline our data practices and your rights as a user.</p>
<p><strong>1.  Information We Collect</strong></p>
<p>1.1 Personal Information You Provide</p>
<p>We collect personal information you voluntarily provide, such as:Name, email address, and phone number when you register or communicate with us. User-generated content, such as posts on blogs, forums, or other Platform features.</p>
<p>1.2 Usage Data</p>
<p>We collect information about your interactions with the Platform, including:Device information, such as IP address, browser type, operating system, and referral URLs.  Behavioral data, such as features accessed, pages viewed, and time spent on the Platform.</p>
<p>1.3 Cookies and Tracking Technologies</p>
<p>We use cookies, web beacons, and similar technologies to personalize your experience, improve functionality, and gather aggregate analytics data.</p>
<p>1.4 Information Collected via Mobile Apps</p>
<p>When you use the Smart’n app available on the Apple App Store or Google Play Store, we may collect:</p>
<p>Device Data: Information about your device, such as device model, operating system, unique device identifiers, and mobile network information.</p>
<p>App Usage Data: Logs of app interactions, including time spent on various features, crashes, and performance metrics.</p>
<p>Optional Permissions: Access to your device's camera, microphone, or location, but only with your explicit consent and solely for features requiring these permissions.</p>
<p><strong>2.  How We Use Your Information</strong></p>
<p>2.1 To Provide and Improve Services</p>
<p>We use your data to: Operate and improve the Platform and its features. Customize user experiences and provide tailored content.</p>
<p>2.2 For Training and Improving Algorithms</p>
<p>To enhance the accuracy, efficiency, and relevance of Smart’n’s artificial intelligence (AI) and machine learning (ML) models, we may use de-identified and aggregated user data for: Training and fine-tuning our algorithms to improve responses and user experience.Conducting research and analysis to develop new features and capabilities.</p>
<p><i>Note: Any data used for training or improvement purposes will be de-identified to remove personally identifiable information (PII) and will not be used in a manner that links it back to individual users.</i></p>
<p>2.3 Marketing and Communications</p>
<p>With your consent, we may send you:</p>
<p>Newsletters, promotional emails, and updates about our services. Surveys and requests for feedback to enhance our offerings.</p>
<p>2.4 Legal Compliance and Protection</p>
<p>We may use your information to:  Comply with applicable laws, regulations, or legal obligations. Protect the rights, property, or safety of our users, employees, or others.</p>
<p><strong>3. Sharing Your Information</strong></p>
<p>We will not share your personal information except as described in this Privacy Policy:</p>
<p>3.1 With Service Providers</p>
<p>We may share your information with trusted third-party service providers who assist us in delivering and improving the Platform.</p>
<p>3.2 Legal Requirements</p>
<p>We may disclose your information to comply with legal obligations or enforce our terms of use.</p>
<p>3.3 Business Transfers</p>
<p>In the event of a merger, acquisition, or sale of assets, your information may be transferred to the new owner.</p>
<p>3.4 With Your Consent</p>
<p>We may share your information for other purposes if you provide explicit consent.</p>
<p>3.5 Use of Third-Party Services</p>
<p>The Smart’n app integrates with third-party software development kits (SDKs) for the following purposes:</p>
<p>Analytics: To understand app performance and improve user experience.<br>
Push Notifications: To send updates and reminders (requires user opt-in).<br>
Error Reporting: To diagnose and fix technical issues.<br>
</p>
<p>3.6 App Tracking Transparency (iOS Users)</p>
<p>For iOS devices, the Smart’n app complies with Apple’s App Tracking Transparency framework. If tracking is used, you will be presented with a prompt requesting your consent to track your activity across apps and websites owned by other companies.</p>
<p><strong>4. Your Privacy Rights</strong></p>
<p>You have the following rights regarding your personal data:</p>
<p>Access: Request a copy of your personal data.<br>
Correction: Request corrections to inaccurate or incomplete data.<br>
Erasure: Request deletion of your data, subject to legal retention requirements.<br>
Objection: Object to the processing of your data for specific purposes.<br>
Portability: Request transfer of your data to another organization.<br>
Withdrawal of Consent: Opt out of communications or withdraw consent for data processing.<br>
To exercise these rights, contact us at support@smartn.ca.<br>
</p>
<p><strong>5. Data Retention</strong></p>
<p>We retain your data only as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law.</p>
<p>Cached data or locally stored information on your device is retained only for as long as necessary to provide app functionality. You can clear cached data through your device’s settings at any time.</p>
<p><strong>6. Security</strong></p>
<p>We implement industry-standard security measures to protect your information from unauthorized access, disclosure, or misuse. However, no system is completely secure, and we cannot guarantee absolute security.</p>
<p><strong>7. Cookies and Tracking Technologies</strong></p>
<p>We use cookies and similar technologies to:</p>
<p>Enhance your user experience. Gather analytics to improve the Platform. You can manage your cookie preferences through your browser settings. Note that disabling cookies may impact Platform functionality.</p>
<p><strong>8. Changes to This Privacy Policy</strong></p>
<p>We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. The revised policy will be effective upon posting, and the updated date will be noted.</p>
<p><strong>9. Contact Us</strong></p>
<p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:  Email: connect@smartn.ca</p>
<p><strong>10. Mobile App Listings</strong></p>
<p>You can download the Smart’n app on the following platforms:</p>
<ul>
  <li>Apple App Store</li>
  <li>Google Play Store</li>
</ul>
`;

export default document;
