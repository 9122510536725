import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { quizSelectors } from '../StudySpace/redux/slice';
import { firstNameQuestionId, roleQuestionId, roleQuestionIdV2, readyQuestionID } from '../OnBoarding/constants';
import { EApplyQuizState, EApplyTypes, EAttemptTypes, IAttempt, ILooseObject, IQuestionAnswers } from '../types';
import { onBoardingQuestionsV3 } from '../StudySpace/staticConstants';
import { applySelector } from '../Apply/redux/slice';
import { APPLY_EXTRA_QUESTION, IGNORED_QUESTIONS_DONE } from '../Apply/constants';
import { clicksSelector } from '../ClicksQuiz/redux/slice';

const isFirstOnboardingPartSolved = (attempt: IAttempt) => {
  const firstNameQuestion = attempt.questions.find(question => question.questionId === firstNameQuestionId);
  const roleQuestion = attempt.questions.find(question => question.questionId === roleQuestionId);
  const readyQuestion = attempt.questions.find(question => question.questionId === readyQuestionID);
  return firstNameQuestion?.response && roleQuestion?.response && readyQuestion?.response;
};

const isRoleQuestionAnsweredV2 = (attempt: IAttempt) => {
  const roleQuestion = attempt.questions.find(question => question.questionId === roleQuestionIdV2);
  return roleQuestion?.response;
};

const isNewestOnboardingVersion = (attempt?: IAttempt) => {
  if (!attempt) return true;
  return attempt.questions.every(question =>
    onBoardingQuestionsV3.some(onBoardingQuestion => question.questionId === onBoardingQuestion.questionId),
  );
};

const getLiveApplyQuizState = (
  applyAttempt?: IAttempt,
  enhanceApplyAttempt?: IAttempt,
  questionAnswers?: ILooseObject,
  isApplyEnhancementFetched?: boolean,
): EApplyQuizState => {
  if (!applyAttempt || !questionAnswers) return EApplyQuizState.BASE_NOT_STARTED;

  const isEnhanceApplyAttemptDone = enhanceApplyAttempt?.questions.every(
    question => !!questionAnswers[question.questionId]?.isDone,
  );
  if (isEnhanceApplyAttemptDone || !isApplyEnhancementFetched) return EApplyQuizState.ENHANCE_DONE; // Extra Check
  const isApplyAttemptDone =
    questionAnswers[APPLY_EXTRA_QUESTION]?.response ||
    applyAttempt.questions
      .filter(question => !IGNORED_QUESTIONS_DONE.includes(question.questionId))
      .every(question => !!questionAnswers[question.questionId]?.isDone);
  const notDoneQ = applyAttempt.questions
    .filter(question => !IGNORED_QUESTIONS_DONE.includes(question.questionId))
    .find(question => !questionAnswers[question.questionId]?.isDone);

  if (!isApplyAttemptDone) return EApplyQuizState.BASE_STARTED;
  if (!enhanceApplyAttempt) return EApplyQuizState.BASE_DONE;

  if (isEnhanceApplyAttemptDone) return EApplyQuizState.ENHANCE_DONE;

  return EApplyQuizState.ENHANCE_STARTED;
};

const useAttempts = () => {
  const { attempts, onBoardingAttempts, placementAttempts } = useSelector(quizSelectors.allState);
  const {
    attempts: applyAttempts,
    enhancementsAttempts,
    questionAnswers: applyQuestionsAnswers,
    applyQuizFetched,
    applyEnhanceQuizFetched,
    jobAppAttempts,
    jobAppId,
    jobAppsFetched,
  } = useSelector(applySelector.allState);

  const { attempts: clicksAttempts } = useSelector(clicksSelector.allState);

  const attemptsCachedInfo = useMemo(() => {
    if (!attempts && !onBoardingAttempts && !placementAttempts && !applyAttempts) return {};

    const filteredAttempts = attempts?.filter(
      attempt =>
        !attempt.isOnBoarding &&
        !attempt.isNurseGPT &&
        !attempt.isRolePlaceHolder &&
        !attempt.isApply &&
        ![EAttemptTypes.APPLY_ENHANCE, EAttemptTypes.APPLY, EAttemptTypes.APPLY_JOB_APPS].includes(attempt.quizType),
    );
    const isPlacementTestDone = placementAttempts?.some(attempt => attempt.isDone);
    // Either there is a done placement test attempt or return the first placement test attempt is found
    const currentPlacementTest = placementAttempts?.find(attempt => attempt.isDone) || placementAttempts?.[0];
    const currentOnBoardingTest =
      onBoardingAttempts?.find(attempt => attempt.isDone) ||
      onBoardingAttempts?.find(attempt => isFirstOnboardingPartSolved(attempt)) ||
      onBoardingAttempts?.[0];
    const isFullyOnboarded =
      onBoardingAttempts?.some(attempt => attempt.isDone) || !isNewestOnboardingVersion(currentOnBoardingTest);

    const isOnboarded =
      jobAppId ||
      jobAppAttempts?.length > 0 ||
      isFullyOnboarded ||
      onBoardingAttempts?.some(attempt => isFirstOnboardingPartSolved(attempt)) ||
      !jobAppsFetched;

    //const isOnboarded = true;
    const readinessAttempts = attempts?.filter(attempt => attempt.isReadiness);
    const currentReadinessAttempt = readinessAttempts?.[0];

    const isRoleQuestionAnswered =
      !!onBoardingAttempts?.find(isFirstOnboardingPartSolved) || !!onBoardingAttempts?.find(isRoleQuestionAnsweredV2);

    const rolePlaceholderAttempt = attempts?.find(attempt => attempt.isRolePlaceHolder);

    const currentApplyAttempt =
      applyAttempts?.find(attempt => attempt.isDone) ||
      //applyAttempts?.find(attempt => isFirstOnboardingPartSolved(attempt)) ||
      applyAttempts?.[0];

    const currentApplyEnhancementAttempt =
      enhancementsAttempts.find(attempt => attempt.isDone) || enhancementsAttempts[0];

    // Logic setting from saga
    const currentJobAppAttempt = jobAppAttempts?.[0];

    const applyAttempt = currentApplyAttempt || currentJobAppAttempt;

    const applyQuizState = applyQuizFetched
      ? getLiveApplyQuizState(
          applyAttempt,
          currentApplyEnhancementAttempt,
          applyQuestionsAnswers,
          applyEnhanceQuizFetched,
        )
      : EApplyQuizState.ENHANCE_DONE;

    const currentClicksAttempt = clicksAttempts?.[0];

    return {
      onBoardingAttempts,
      placementTestAttempts: placementAttempts,
      isOnboarded,
      isFullyOnboarded,
      isPlacementTestDone,
      filteredAttempts,
      allAttempts: attempts,
      currentPlacementTest,
      currentOnBoardingTest,
      readinessAttempts,
      currentReadinessAttempt,
      isRoleQuestionAnswered,
      rolePlaceholderAttempt,
      applyAttempts,
      currentApplyAttempt: applyAttempt,
      currentApplyEnhancementAttempt,
      applyQuizState,
      currentClicksAttempt,
      currentJobAppAttempt,
      jobAppsFetched,
      applyQuizFetched,
    };
  }, [
    attempts,
    attempts?.length,
    onBoardingAttempts,
    onBoardingAttempts?.length,
    applyAttempts?.length,
    enhancementsAttempts,
    enhancementsAttempts?.length,
    applyQuestionsAnswers,
    clicksAttempts,
    clicksAttempts?.length,
    jobAppAttempts,
    jobAppAttempts?.length,
    jobAppId,
  ]);

  return attemptsCachedInfo;
};

export default useAttempts;
