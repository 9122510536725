import { Link } from 'react-router-dom';
import { sampleSize, sample } from 'lodash';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import {
  PageContainer,
  ChatContainer,
  PlusButtonContainer,
  PlusButton,
  ModalOverlay,
  ModalContainer,
  ModalTitle,
  ModalBody,
  ModalButtonsContainer,
  ModalFooter,
  LargeModalTitle,
} from './styled';
import { en } from '../../i18n';
import { usePublicNurseGPT, useSeoTags } from '../hooks';
import ChatBody from './components/ChatBody';
import PromptInput from './components/PromptInput';
import ErrorModal from './components/ErrorModal';
import Button from '../components/Button';
import { ReactComponent as PlusIcon } from '../../images/plusWhite.svg';
import { ReactComponent as CloseIcon } from '../../images/icons/nurseGPT/helpfulTipsClose.svg';
import { loginPath } from '../Router/paths';
import Spinner from '../components/Spinner';
import { Modal } from '@mui/material';
import { EReconnectStatus } from '../hooks/NurseGPT/useCommonNurseGPT';

const {
  prompts,
  discardSessionModalTitle,
  discardSessionModalBody,
  createAccount,
  newSession,
  haveAccount,
  login,
  uploadModalTitle,
  uploadModalBody,
  welcomeModalTitle,
  welcomeModalBody,
  notNow,
} = en.nurseGPT;

const nursePrompts = sampleSize(Object.values(prompts.nurse), 3).map(category => sample(category));
nursePrompts.unshift(prompts.defaultPublicPrompt);

const PublicNurseGPT = () => {
  const seo = useSeoTags({ pageName: 'askSmartn' });
  const {
    state: {
      prompt,
      chat,
      messagesWrapperRef,
      typedResponseRef,
      loadingResponse,
      isUnstarted,
      inputRef,
      typedResponse,
      currentSession,
      svgLoader,
      showErrorModal,
      copyToolTipText,
      errors,
      showDiscardSessionModal,
      showUploadModal,
      showWelcomeModal,
      hideExamples,
      loadingWebSession,
      initialConnection,
      isReconnecting,
      isServerError,
    },
    control: {
      handlePromptChange,
      handlePromptSubmit,
      onKeyDown,
      handleExampleClick,
      getPlaceholder,
      refresh,
      setCopyTooltipText,
      toggleDiscardSessionModal,
      toggleUploadModal,
      toggleWelcomeModal,
      handleStartNewChat,
      navigateToRegistration,
      navigateToLogin,
      setIsServerError,
    },
  } = usePublicNurseGPT();

  const renderDiscardSessionModal = () => {
    return (
      showDiscardSessionModal && (
        <ModalOverlay zIndex={11}>
          <ClickAwayListener onClickAway={toggleDiscardSessionModal}>
            <ModalContainer>
              <CloseIcon onClick={toggleDiscardSessionModal} />
              <ModalTitle>{discardSessionModalTitle}</ModalTitle>
              <ModalBody>{discardSessionModalBody}</ModalBody>
              <ModalButtonsContainer>
                <Button variant="secondary" onClick={navigateToRegistration}>
                  {createAccount}
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    toggleDiscardSessionModal();
                    handleStartNewChat();
                  }}
                >
                  {newSession}
                </Button>
              </ModalButtonsContainer>
              <ModalFooter>
                {haveAccount} <Link to={loginPath}>{login}</Link>
              </ModalFooter>
            </ModalContainer>
          </ClickAwayListener>
        </ModalOverlay>
      )
    );
  };

  const renderUploadModal = () => {
    return (
      showUploadModal && (
        <ModalOverlay zIndex={11}>
          <ClickAwayListener onClickAway={toggleUploadModal}>
            <ModalContainer>
              <CloseIcon onClick={toggleUploadModal} />
              <ModalTitle>{uploadModalTitle}</ModalTitle>
              <ModalBody>{uploadModalBody}</ModalBody>
              <ModalButtonsContainer>
                <Button variant="secondary" onClick={navigateToRegistration}>
                  {createAccount}
                </Button>
                <Button variant="primary" onClick={navigateToLogin}>
                  {login}
                </Button>
              </ModalButtonsContainer>
            </ModalContainer>
          </ClickAwayListener>
        </ModalOverlay>
      )
    );
  };

  const renderWelcomeModal = () => {
    return (
      showWelcomeModal && (
        <ModalOverlay zIndex={11}>
          <ClickAwayListener onClickAway={toggleWelcomeModal}>
            <ModalContainer>
              <CloseIcon onClick={toggleWelcomeModal} />
              <LargeModalTitle>{welcomeModalTitle}</LargeModalTitle>
              <ModalBody>{welcomeModalBody}</ModalBody>
              <ModalButtonsContainer>
                <Button variant="secondary" onClick={toggleWelcomeModal}>
                  {notNow}
                </Button>
                <Button variant="primary" onClick={navigateToRegistration}>
                  {createAccount}
                </Button>
              </ModalButtonsContainer>
              <ModalFooter>
                {haveAccount} <Link to={loginPath}>{login}</Link>
              </ModalFooter>
            </ModalContainer>
          </ClickAwayListener>
        </ModalOverlay>
      )
    );
  };

  const renderDimConnection = () => {
    if (isReconnecting && !showErrorModal) return <ModalOverlay zIndex={11} />;
    return null;
  };

  if (loadingWebSession || !initialConnection)
    return (
      <PageContainer>
        <Spinner loading={loadingWebSession || !initialConnection} />
      </PageContainer>
    );

  return (
    <PageContainer>
      {seo}
      {renderWelcomeModal()}
      {renderUploadModal()}
      {renderDiscardSessionModal()}
      {renderDimConnection()}
      {showErrorModal && <ErrorModal refresh={refresh} />}
      <PlusButtonContainer>
        {!isReconnecting && (
          <PlusButton onClick={() => toggleDiscardSessionModal()}>
            <PlusIcon />
          </PlusButton>
        )}
      </PlusButtonContainer>
      <ChatContainer isPublic>
        <ChatBody
          isUnstarted={isUnstarted}
          messagesWrapperRef={messagesWrapperRef}
          currentSession={currentSession}
          chat={chat}
          loadingResponse={loadingResponse}
          typedResponse={typedResponse}
          typedResponseRef={typedResponseRef}
          svgLoader={svgLoader}
          copyToolTipText={copyToolTipText}
          setCopyTooltipText={setCopyTooltipText}
          prompts={nursePrompts}
          handleExampleClick={handleExampleClick}
          hideExamples={hideExamples}
          userIconLetter=""
          isPublic
        />
        <PromptInput
          prompt={prompt}
          handlePromptChange={handlePromptChange}
          onKeyDown={onKeyDown}
          inputRef={inputRef}
          getPlaceholder={getPlaceholder}
          handlePromptSubmit={handlePromptSubmit}
          loadingResponse={loadingResponse}
          errors={errors}
          toggleUploadModal={toggleUploadModal}
          disableUpload
          isReconnecting={isReconnecting}
          isServerError={isServerError}
          setIsServerError={setIsServerError}
          isUnstarted={isUnstarted}
          isPublic
        />
      </ChatContainer>
    </PageContainer>
  );
};

export default PublicNurseGPT;
