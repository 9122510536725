import {
  UnstartedChatContainer,
  UnstartedChatTitle,
  UnstartedChatExamplesContainer,
  UnstartedChatExamples,
  UnstartedChatExample,
  UnstartedChatIcon,
  UnstartedChatHeader,
  UnstartedChatExampleTitle,
} from '../styled';
import Spinner from '../../components/Spinner';
import { ReactComponent as NurseGPTIcon } from '../../../images/nurseGPT.svg';
import { en } from '../../../i18n';

const { unstartedChatTitle } = en.nurseGPT;

const UnstartedChat = ({
  isUnstartedLoading,
  prompts,
  handleExampleClick,
  hideExamples,
  isPublic,
}: {
  isUnstartedLoading: boolean;
  prompts: (string | undefined)[];
  handleExampleClick: (prompt: string) => void;
  hideExamples?: boolean;
  isPublic?: boolean;
}) => {
  return (
    <UnstartedChatContainer hideExamples={hideExamples} isPublic={isPublic}>
      <UnstartedChatHeader>
        <UnstartedChatIcon>
          <NurseGPTIcon />
        </UnstartedChatIcon>
        <UnstartedChatTitle>{unstartedChatTitle}</UnstartedChatTitle>
      </UnstartedChatHeader>
      {!hideExamples && isPublic && (
        <UnstartedChatExamplesContainer>
          <UnstartedChatExamples>
            {isUnstartedLoading ? (
              <Spinner loading={isUnstartedLoading} />
            ) : (
              prompts.map(prompt => (
                <UnstartedChatExample onClick={() => handleExampleClick(prompt || '')}>
                  <UnstartedChatExampleTitle>{prompt}</UnstartedChatExampleTitle>
                </UnstartedChatExample>
              ))
            )}
          </UnstartedChatExamples>
        </UnstartedChatExamplesContainer>
      )}
    </UnstartedChatContainer>
  );
};

export default UnstartedChat;
